/*
This file is a part of proprietary software.
Its usage or modification is prohibited in any environment not associated with the Tannenhaus Hotel & Restaurant.
*/
/*
    Created on : 29.11.2018, 11:11:54
    
*/

hr{
	&.small_divider{
		width: 40px;
	}
	&.larger_divider{
		left: 50%;
		bottom: 15%;
		height: 40vh
	}
	&.custom_divider{
		border-color: $orange;
	}
	&.connector{
		/*left: 22vw;*/
		margin-left: 75px;
		top: 80vh;
		height: 45vh;
		@media(max-width:$breakpoint-medium){
			height: 28vh;
			top: 85vh;
			@media screen and (max-height: 450px){
				top: 95vh;
			}
		}
		@media(min-height:1000px){
			top: 90vh;
			height: 20vh;
		}
		@media(min-height:2500px){
			top: 95vh;
			height: 10vh;
		}
		&.location_divider {
			height: 450px;
			@media(max-height:750px){
				height:350px;
			}
		}
	}
	&.footer_divider{
		border-color: $text_dark;
	}
	&.small_on_mobile{
		left: 50%;
		bottom: 0;
		&.first{
			top: -65%;
		}
		@media(min-width:$breakpoint-medium){
			bottom: 15%;
		}
		
	}
}