/*
This file is a part of proprietary software.
Its usage or modification is prohibited in any environment not associated with the Tannenhaus Hotel & Restaurant.
*/
/*
    Created on : 29.11.2018, 09:20:51

*/

div {
    //NAV
    &.gradient {
        /*		@include filter-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0), vertical);
		$experimental-support-for-svg: true;
		@include background-image(linear-gradient(top, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.25) 50%, rgba(0,0,0,0.2) 80%, rgba(0,0,0,0.1) 90%, rgba(0,0,0,0) 100%));*/
        div {
            nav {
                div {
                    &:not(:nth-child(2)) {
                        z-index: 2;
                    }
                    @media (max-width: $breakpoint-medium) {
                        &:nth-child(2) {
                            z-index: 0;
                        }
                    }
                }
            }
        }
    }
    &.bottom_gradient {
        background: linear-gradient(
            0deg,
            rgba(0, 11, 23, 0.695098107602416) 0%,
            rgba(0, 11, 23, 0.35056029247636555) 30%,
            rgba(0, 11, 23, 0.20210090872286413) 60%,
            rgba(0, 11, 23, 0.10126057258841037) 80%,
            rgba(0, 11, 23, 0) 100%
        );
    }
    &.nav_force_top_align {
        @media (max-width: $breakpoint-medium) {
            align-items: flex-start;
            a {
                align-items: flex-start;
            }
        }
    }
    //GENERAL MODAL SEETINGS
    &.uk-modal-dialog {
        &.uk-margin-auto-vertical {
            @include border-top-radius(3px);
            @include border-bottom-radius(3px);
            & .uk-modal-header {
                @include border-top-radius(3px);
            }
            & .uk-modal-footer {
                @include border-bottom-radius(3px);
            }
        }
    }

    //MENU MODAL
    &#menu {
        z-index: 1;
        & .uk-modal-dialog {
            background-color: rgba(0, 0, 0, 0.8);
            button {
                &.uk-icon {
                    background: unset;
                }
            }
        }
    }

    //SPECIAL STUFF
    &.center_text_on_mobile {
        @media (max-width: $breakpoint-medium) {
            text-align: center;
        }
    }
    $pull-list: 80, 140;
    @each $pull in $pull-list {
        &.pull_up_#{$pull} {
            @media (min-width: $breakpoint-medium) {
                margin-top: -#{$pull}px !important;
            }
        }
    }
    &.pull_up {
        @media (min-width: $breakpoint-medium) {
            margin-top: -140px !important;
        }
    }
    &.remove-padding-left-on-desktop {
        @media (min-width: 1340px) {
            @include padding(null null null 0px);
        }
    }
    &.hamburger {
        outline: none;
        height: 20px;
        z-index: 10;
    }
    &.logo_down{
        z-index: 0!important;
        @media screen and (min-height: 600px) {
            top: 100% !important;
            @media (max-width: 1200px) {
                top: 75% !important;
            }
        }
        &.logo_home {
            @media screen and (min-height: 600px) {
                top: 150% !important;
                @media (max-width: 1200px) {
                    top: 100% !important;
                }
            }
        }
    }
    &.menu_distance_at_home {
        @media screen and (min-height: 600px) and (max-height: 800px) {
            height: 130px;
        }
        @media screen and (min-height: 800px) {
            height: 180px;
        }
    }
    &.small_margin_on_mobile {
        @media (max-width: $breakpoint-medium) {
            margin-top: $global-small-margin !important;
        }
    }

    // Tannenzauber Content
    &.tz_intro {
        background-image: url("../images/tz/tuete_alt.jpg");
        background-size: cover;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 2fr 1fr;
        gap: 0 0;
        grid-auto-flow: row;
        grid-template-areas:
            "tzi_top_text"
            "."
            "tzi_bottom_text";
        & .tzi_top_text {
            grid-area: tzi_top_text;
        }

        & .tzi_bottom_text {
            grid-area: tzi_bottom_text;
        }
    }

    &.tz_content {
        display: grid;
        background-image: linear-gradient(180deg, rgba(13,28,40,1) 0%, rgba(13,28,40,0.75) 33%,  rgba(13,28,40,0.75) 90%, rgba(13,28,40,1) 100%), url(../images/tz/bg.jpg);
        background-size: cover;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 2fr 1fr;
        gap: 0px 0px;
        grid-auto-flow: row;
        grid-template-areas:
            "tzc_top"
            "tzc_center"
            "tcz_bottom";
        & .tzc_center {
            grid-area: tzc_center;
            display: flex;
            align-content: center;
            flex-wrap: wrap;
        }
        & .tzc_top { grid-area: tzc_top; }
        & .tcz_bottom {
            grid-area: tcz_bottom;
            display: flex;
            align-content: center;
            flex-wrap: wrap;
        }
    }
    &.tz_dates {
        display: grid;
        grid-template-columns: repeat(4, 1fr);

        //grid-auto-flow: column;
        justify-content: start;
        align-content: center;
        justify-items: stretch;
        align-items: center;
    }
}
